import React, { useState, useEffect} from "react";
import styles from './PlayerStats.module.scss';
// import { API, Storage } from 'aws-amplify';
// import { withAuthenticator, AmplifySignOut } from "@aws-amplify/ui-react";
// import { listNotes } from './graphql/queries';
// import { createNote as createNoteMutation, deleteNote as deleteNoteMutation } from './graphql/mutations';
import XgPop from '../components/XgPop'
import {Table,Container,Row,Col} from "react-bootstrap"
import Form from 'react-bootstrap/Form'
// import PlayerPop from '../components/PlayerPop'
// import GoogleAd from '../components/GoogleAd'
import SnackAd from '../components/SnackAd'


const axios = require("axios");

function GwInfo(props) {

  const gameWeek = props.gw>0 ? ("Gameweek " + props.gw) : null

  return(
    <Container fluid className ={styles.gwInfo}>
      <Row>
        <Col>
          <h1 className ={styles.gameweekh1}>
            Team Stats
          </h1>
        </Col>
      </Row>
    </Container>
  )
}

function arrw(x,y,z) {
  if (x!=y) {
    return null;
  }
else if(x==y && z==1){
  return (
     <> &#9650; </>
  );
}
else{
  return (
     <> &#9660; </>
  );
}
}

function plus(x) {
  if (x<0) {
    return null;
  }
else{
  return (
     <>+</>
  );
}
}


function TeamStatsXG(props) {

  if(props.fplStats.length==0){
    return null
  }
  else{

    const style_0 = props.rankOrd===0 ? "bg-secondary" : "bg-default";
    const style_1 = props.rankOrd===1 ? "bg-secondary" : "bg-default";
    const style_2 = props.rankOrd===2 ? "bg-secondary" : "bg-default";
    const style_3 = props.rankOrd===3 ? "bg-secondary" : "bg-default";
    const style_4 = props.rankOrd===4 ? "bg-secondary" : "bg-default";
    const style_5 = props.rankOrd===5 ? "bg-secondary" : "bg-default";
    const style_6 = props.rankOrd===6 ? "bg-secondary" : "bg-default";
    const style_7 = props.rankOrd===7 ? "bg-secondary" : "bg-default";
    const style_8 = props.rankOrd===8 ? "bg-secondary" : "bg-default";
    const style_9 = props.rankOrd===9 ? "bg-secondary" : "bg-default";
    const style_10 = props.rankOrd===10 ? "bg-secondary" : "bg-default";
    const style_11 = props.rankOrd===11 ? "bg-secondary" : "bg-default";
    const style_12 = props.rankOrd===12 ? "bg-secondary" : "bg-default";

    // const ord_switch = props.ordType===1 ? 0 : 1;
    // // const gw = props.summary.fixtures_gw;
    // const gameWeek = props.summary.fixtures_gw>0 ? ("Gameweek " + props.summary.fixtures_gw) : null

  return (
    <Table
      responsive
      size="sm"
      className={styles.players_table + " shadow"}
      bordered
    >
      <thead className="thead-dark">
        <tr>
          <th rowSpan="2" className ={ style_0 }
          onClick={()=>props.setUrl(props.newUrl(0,0))}
          style={{verticalAlign:"middle",cursor:"pointer"}}>
          # {arrw(0,props.rankOrd,props.ordType)}
          </th>
          <th rowSpan="2" style={{textAlign:"left",verticalAlign:"middle"}}>Team</th>
          <th colSpan="3">Gameweek {props.gw}</th>
        </tr>
        <tr>
          <th className={style_10} onClick={()=>props.setUrl(props.newUrl(1,0))} style={{cursor:"pointer",paddingLeft:"2px",paddingRight:"0"}}>Win%{arrw(1,props.rankOrd,props.ordType)}</th>
          <th className={style_11} onClick={()=>props.setUrl(props.newUrl(2,0))} style={{cursor:"pointer",paddingLeft:"2px",paddingRight:"0"}}>+2.5 Gls%{arrw(2,props.rankOrd,props.ordType)}</th>
          <th className={style_12} onClick={()=>props.setUrl(props.newUrl(3,0))} style={{cursor:"pointer",paddingLeft:"2px",paddingRight:"0"}}>Clean% {arrw(3,props.rankOrd,props.ordType)}</th>
        </tr>
      </thead>
      <tbody>
        {props.fplStats.map(plyr => (
          <React.Fragment key={plyr.rank}>
          <tr style={{padding:"0"}}>
           <td>{plyr.position}</td>
            <td className ={"border-right"}
              style={{textAlign:"left",paddingBottom:"0"}}
            >
              {plyr.team} <span className='font-italic text-secondary'>vs {plyr.opp} ({plyr.h_a})</span><span className='font-italic text-secondary'>{plyr.form}</span>
            </td>
            <td className={"border-right"}>
              {plyr.win_chance}
            </td>
            <td>
              {plyr.goals_chance}
            </td>
            <td>
              {plyr.clean_chance}
            </td>
          </tr>
          </React.Fragment>
        ))}
      </tbody>
    </Table>
  );
}
}

class SelForm extends React.Component {
// function PhaseForm() {
constructor(props) {
    super(props);
    this.state = {value: '2'};
    // this.setUrl = this.props.setUrl;
    // this.newUrl = this.props.newUrl;
    this.handleChangePos = this.handleChangePos.bind(this);
    this.handleChangeDiff = this.handleChangeDiff.bind(this);
    this.handleChangePrice= this.handleChangePrice.bind(this);
    this.handleChangeTeam= this.handleChangeTeam.bind(this);
    this.handleChangePeriod= this.handleChangePeriod.bind(this);
}

  handleChangePos(event) {
    this.props.selections.pos = event.target.value;
    this.props.setUrl(this.props.newUrl(this.props.selections,this.props.rankOrd,1));
    console.log(event.target.value);
  }

  handleChangeDiff(event) {
    this.props.selections.diff = event.target.value;
    this.props.setUrl(this.props.newUrl(this.props.selections,this.props.rankOrd,1));
    console.log(event.target.value);
  }

  handleChangePrice(event) {
    this.props.selections.price = event.target.value;
    this.props.setUrl(this.props.newUrl(this.props.selections,this.props.rankOrd,1));
    console.log(event.target.value);
  }

  handleChangeTeam(event) {
    this.props.selections.tcode = event.target.value;
    this.props.setUrl(this.props.newUrl(this.props.selections,this.props.rankOrd,1));
    console.log(event.target.value);
  }

  handleChangePeriod(event) {
    this.props.selections.period = event.target.value;
    this.props.setUrl(this.props.newUrl(this.props.selections,this.props.rankOrd,1));
    console.log(event.target.value);
  }

  render() {
    return  (
      <>
      <Row style={{textAlign:"centre",marginLeft: -5,marginRight: -5  }}>
      <Col style={{ paddingLeft: 5,paddingRight: 5  }}>
      <Form className={styles.inputForm}>
        <Form.Group controlId="exampleForm.ControlSelect1">
          <Form.Control
            as="select" value={this.props.selections.pos}
            onChange={this.handleChangePos}
            size="sm"
            >
            <option value='0'>All</option>
            <option value='1'>GK</option>
            <option value='2'>Def</option>
            <option value='3'>Mid</option>
            <option value='4'>Fwd</option>
          </Form.Control>
        </Form.Group>
      </Form>
      </Col>
      <Col style={{ paddingLeft: 5,paddingRight: 5  }}>
      <Form className={styles.inputForm}>
        <Form.Group controlId="exampleForm.ControlSelect2">
          <Form.Control
            as="select" value={this.props.selections.diff}
            onChange={this.handleChangeDiff}
             size="sm"
            >
            <option value='0'>All</option>
            <option value='1'>30%</option>
            <option value='2'>20%</option>
            <option value='3'>10%</option>
          </Form.Control>
        </Form.Group>
      </Form>
      </Col>
      <Col style={{ paddingLeft: 5,paddingRight: 5  }}>
      <Form className={styles.inputForm}>
        <Form.Group controlId="exampleForm.ControlSelect3">
          <Form.Control
            as="select" value={this.props.selections.price}
            onChange={this.handleChangePrice}
            size="sm"
            >
            <option value='0'>All</option>
            <option value='1'>£12M</option>
            <option value='2'>£11M</option>
            <option value='3'>£10M</option>
            <option value='4'>£9M</option>
            <option value='5'>£8M</option>
            <option value='6'>£7M</option>
            <option value='7'>£6M</option>
            <option value='8'>£5M</option>
            <option value='9'>£4M</option>
          </Form.Control>
        </Form.Group>
      </Form>
      </Col>
      <Col style={{ paddingLeft: 5,paddingRight: 5  }}>
      <Form className={styles.inputForm}>
        <Form.Group controlId="exampleForm.ControlSelect4">
          <Form.Control
            as="select" value={this.props.selections.tcode}
            onChange={this.handleChangeTeam}
            size="sm"
            >
            <option value='0'>All</option>
            <option value='1'>Arsenal</option>
            <option value='2'>Aston Villa</option>
            <option value='3'>Brentford</option>
            <option value='4'>Brighton</option>
            <option value='5'>Burnley</option>
            <option value='6'>Chelsea</option>
            <option value='7'>Crystal Palace</option>
            <option value='8'>Everton</option>
            <option value='9'>Leeds</option>
            <option value='10'>Leicester</option>
            <option value='11'>Liverpool</option>
            <option value='12'>Man City</option>
            <option value='13'>Man Utd</option>
            <option value='14'>Newcastle</option>
            <option value='15'>Norwich</option>
            <option value='16'>Southampton</option>
            <option value='17'>Spurs</option>
            <option value='18'>Watford</option>
            <option value='19'>West Ham</option>
            <option value='20'>Wolves</option>
          </Form.Control>
        </Form.Group>
      </Form>
      </Col>
      </Row>
      <Row style={{textAlign:"center"}}>
      <Col>
      <Form>
          <Form.Group controlId="exampleForm.ControlSelect5">
            <Form.Check
              inline
              checked={this.props.selections.period == "0"}
              label="All season"
              name="group1"
              type="radio"
              id="inline-1"
              value="0"
              onChange={this.handleChangePeriod}
            />
            <Form.Check
              inline
              label="Last GW"
              name="group1"
              type="radio"
              id="inline-2"
              value="1"
              onChange={this.handleChangePeriod}
            />
            <Form.Check
              inline
              label="Last 3 GWs"
              name="group1"
              type="radio"
              id="inline-3"
              value="2"
              onChange={this.handleChangePeriod}
            />
          </Form.Group>
      </Form>
      </Col>
      </Row>
      </>
    );
}
}



// <Row>
// <Col>
// <Form className={styles.inputForm}>
//   <Form.Group controlId="exampleForm.ControlSelect5">
//     <Form.Control
//       as="select" value={this.props.selections.period}
//       onChange={this.handleChangePeriod}
//       size="sm"
//       >
//       <option value='0'>All</option>
//       <option value='1'>Last GW</option>
//       <option value='2'>Last 3 GWs</option>
//     </Form.Control>
//   </Form.Group>
// </Form>
// </Col>
// </Row>

// var pos_get = 0;


// const live_url = 'https://o8bbxwfg8k.execute-api.eu-west-1.amazonaws.com/dev/api/fpl_alerts_aws_live_19';


const useMatchesState = () => {
  const live_url = 'https://o8bbxwfg8k.execute-api.eu-west-1.amazonaws.com/dev/api/xg_teams';
  // const [gw, setGW] = useState(0);
  const [url, setUrl] = useState(live_url)
  const [responseData, setResponseData] = useState([]);

  //PLAYER POP UP STATE
  // const [show, setShow] = useState(false);
  // const [plData, setplData] = useState([]);
  //
  // const handleClose = () => setShow(false);
  // const handleShow = async (newPlCode) => {
  //
  //   const plCode_url = "https://o8bbxwfg8k.execute-api.eu-west-1.amazonaws.com/dev/api/xg_stats_full?pl_code="+newPlCode
  //   console.log('fetch_url:'+plCode_url)
  //   const response = await axios.get(plCode_url);
  //   setplData(response.data);
  //   console.log("fetchran_plcode");
  //   setShow(true);
  //   };
  //
  //   var xgInfo = [];
  //
  //   if (Object.keys(plData).length > 0) {
  //     xgInfo = plData.info;
  //   }
//END

  const newUrl = (ord,new_x) => {

    if(new_x==1){
      return 'https://o8bbxwfg8k.execute-api.eu-west-1.amazonaws.com/dev/api/xg_teams?ord='+ord+'&ord_type='+ordType;
    }
    else if(ord === rankOrd && ordType==0 && new_x==0){
      return 'https://o8bbxwfg8k.execute-api.eu-west-1.amazonaws.com/dev/api/xg_teams?ord='+ord+'&ord_type=1';
    }
    else if(ord===0 && ord != rankOrd) {
      return 'https://o8bbxwfg8k.execute-api.eu-west-1.amazonaws.com/dev/api/xg_teams?ord='+ord+'&ord_type=1';
    }
      else {
        return 'https://o8bbxwfg8k.execute-api.eu-west-1.amazonaws.com/dev/api/xg_teams?ord='+ord+'&ord_type=0';
      }
    }

  var fplstats = [];
  var gw = 0;
  var rankOrd =0;
  var ordType = 0;
  var stats_updated = "";

  if (Object.keys(responseData).length > 0) {

    fplstats = responseData.stats;
    gw = responseData.summary.fixtures_gw;
    rankOrd = responseData.summary.ord;
    ordType = responseData.summary.ord_type;
    stats_updated = responseData.summary.stats_updated;

  }

  const fetchFpl = async (url) => {

    console.log('fetch_url:'+url)

    const response = await axios.get(url);
    setResponseData(response.data);
    console.log("fetchran");

  };

  useEffect(() => {
    fetchFpl(url);
    // setGW(responseData.data.status.gw);
    console.log("useeffect ran");
    const intervalId = setInterval(() => {  //assign interval to a variaable to clear it
      fetchFpl(url);
      console.log("useeffect ran again");
    }, 600000)

    return () => {
      clearInterval(intervalId); //This is important
      console.log("clearInterval");
    }

  }, [url])
  // },)

return {gw, fplstats, setUrl, newUrl,rankOrd,ordType,stats_updated};
}


// <Container fluid className={styles.match_all + " shadow-lg"} style={{padding:"10px"}}>
//   <PlayerStatsXG fplStats ={fplstats} selections = {selections} setUrl={setUrl} newUrl = {newUrl} rankOrd={rankOrd} ordType={ordType} style={{padding:"20"}}/>
// </Container>

const Stats = () => {

  const {
    gw,
    fplstats,
    setUrl,
    newUrl,
    rankOrd,
    ordType,
    stats_updated,
  } = useMatchesState();

  return (
    <Container fluid style={{ paddingLeft: 0,paddingRight: 5  }}>
      <Row>
        <Col xs={{span:12, order:2}} lg={{span:3, order:1}} style={{ marginLeft: 0 }}>
        <SnackAd ad_type = "skyscraper" ad_num = {1}/>
        </Col>
        <Col xs={{span:12, order:1}} lg={{span:6, order:2}} className={styles.App2}>
          <GwInfo gw={gw}/>
          <TeamStatsXG className={styles.stats_box + " shadow-lg"} gw ={gw} fplStats ={fplstats} setUrl={setUrl} newUrl = {newUrl} rankOrd={rankOrd} ordType={ordType}/>
          <Row className={styles.ft_text}>
            <Col>
            <p>Last Updated: {stats_updated}</p>
            <p>Gls:Goals Scored, GlsA: Goals Against, xG:Expteced Goals, xA:Expected Goals Against<br/>
            xG Pred: Predicted difference in goals based on xG & xGA of teams (home / away specific)<br/>
              Win%:Implied chance of win from bookmakers odds, Clean%:Implied chance of keeping clean sheet from bookmakers odds</p>
            <p> All Expected data (e.g xG) from <a href="https://fbref.com/en/comps/9/Premier-League-Stats">FBREF</a></p>
            </Col>
          </Row>
        </Col>
        <Col xs={{span:12, order:3}} lg={{span:3, order:3}} style={{ marginRight: 0 }}>
        <SnackAd ad_type = "skyscraper" ad_num = {2}/>
        </Col>
      </Row>
    </Container>
  );
}

export default Stats;
